import { Moment } from 'moment/moment'
import { ISubcontractor } from 'src/fdvu/types/ISubcontractor'
import { IRespnosible } from 'src/service/DeliveryService'
import {
  IContract,
  IDiscipline,
  ISingleUser,
  ISystem,
  IUserAccessControl,
  IUserData,
  ITestDocumentType,
  TestDocumentStatuses,
} from 'src/service/OrgTypes'
import { IDocumentComment, IDocumentType, IStepCode, IStepData } from './IFlow'
import { IFolderTreeData } from './IFolderTreeData'
import { IMetaValue } from './IMetaData'

export interface IPaginatedFileContainer {
  file_containers: IFileContainer[]
  total_pages: number
}

export interface IFileContainerStep {
  duedate: string
  responsible: ISingleUser
  responsible_id: number
  start_time?: string
}

export interface OrgRequirement {
  id: number
  due_date: Moment
  title: string
}

export interface IFileContainer extends IUserAccessControl {
  id: number
  name?: string
  record_id?: string
  current_step_id: number
  current_step?: IStepData
  folder_id: number
  folder?: IFolderTreeData
  created_by_id: number
  created_by?: ISingleUser
  file_type?: string
  document_type?: IDocumentType
  document_type_id?: number
  test_document_type?: ITestDocumentType
  test_document_type_id?: number
  responsible_id?: number
  discipline_id?: number
  contract_id?: number
  discipline?: IDiscipline
  responsible?: IUserData
  contract?: IContract
  required_fields?: IMetaValue[]
  optional_fields?: IMetaValue[]
  overdue_date?: string | null
  current_file_container_step?: IFileContainerStep
  current_file_container_step_id?: number
  project_files?: IProjectFile[]
  created_at?: string
  updated_at?: string
  systems?: ISystem[]
  current_revision?: IDocumentRevision
  is_fdv?: boolean
  meta_data?: Record<string, IMetaValue>
  project_id?: number
  folder_name?: string
  requirements?: OrgRequirement[]
  subcontractors?: ISubcontractor[]
  status?: TestDocumentStatuses
  reset_record_id?: boolean
}

export interface IDocumentReview {
  id: number
  comment: string
  status: string
  file_url: string
  revision_id: number
  user_id: number
  created_at: string
  updated_at: string
}
export interface IDocumentRevision {
  id: number
  name: string
  reviews: IDocumentReview
  project_files: IProjectFile[]
  latest_file: IFileVersion
  file_versions: IFileVersion[]
  status: 'approved' | 'rejected' | 'in_progress'
  comment?: string
  file_container_step_id: number
  file_container_id?: number
  file_container?: IFileContainer
  responsible_id: number
  closed_by_id: number
  created_by_id: number
  created_at: string
  updated_at: string
  comments: IDocumentComment[]
  reason?: string
  action?: 'created_revision' | 'moved_to_milestone'
  step_code_id?: string
  step_code?: IStepCode
  planned_start_time: string
  planned_duedate: string
  planned_duration: number
  actual_duration: number
  start_time: string
  closed_date?: string
}

export interface IDocumentHistoryResponse {
  can_edit: boolean
  history: IDocumentHistory[]
}

export interface IDocumentHistory {
  created_at: string
  updated_at: string
  file_container_id: number
  id: number
  reviewers_by_contract: ISingleUser[]
  revisions: IDocumentRevision[]
  responsible: IRespnosible
  responsible_id: number
  step?: IStepData
  archived_step?: IStepData
  step_id: number
  closed_by?: ISingleUser
  closed_by_id?: string
  closed_date?: string
  comment?: string
  start_time?: string
  reason?: string
  current_revision?: IDocumentRevision
  current_revision_id?: number
  duedate?: string
  step_code_id?: string
  step_code?: IStepCode
  project_files: IProjectFile[]
  closed_by_subcontractor?: boolean
  subcontractor: ISubcontractor
  subcontractor_id: string
}

export enum FileStatusEnum {
  NOT_CHECKED = 'not_checked',
  IN_PROGRESS = 'in_progress',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export interface IProjectFile {
  id: number
  name: string
  file_url: string
  file_container_id: number
  _metadata: any
  file_versions: IFileVersion[]
  lastest_version?: IFileVersion
  created_by_id?: number
  created_at: string
  updated_at: string
  fileName?: string
  status: FileStatusEnum
  is_main_file: boolean
}
export interface IFileVersion {
  name?: string
  id?: number
  status?: FileStatusEnum
  file_url: string
  start_time: string
  deadline: string
  content_type: string
  step_id: number
  project_file_id: number
  version_number: number
  created_by_id: number
  assigned_to_id: number
  created_at: string
  updated_at: string
  file_size: string
}
