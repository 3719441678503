import { twMerge } from 'tailwind-merge'
import { IStepData } from 'src/document/types/IFlow'
import { styleClass as columnStyleClass } from 'src/ui-elements/Table/Columns'

interface IDocListStageProps {
  step: IStepData
}

const Step = ({ step }: IDocListStageProps) => {
  return (
    <div className={twMerge('flex items-center', columnStyleClass.cell)}>
      <span
        className={
          'rounded-full w-5 text-center text-xs text-white font-medium bg-blue-root'
        }
      >
        {step?.sequence}
      </span>
      <span
        className={'ml-1 font-medium text-xs text-gray-700 first-capitalize'}
      >
        {step?.name}
      </span>
    </div>
  )
}

export default Step
